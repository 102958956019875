
import { Component, Vue, Prop } from 'vue-property-decorator';
import VenueApiService from '@/api/http/VenueApiService';
import { AxiosResponse } from 'axios';

@Component({})
export default class VenueLegal extends Vue {
  @Prop({ type: String, required: true }) public venueId!: string;
  @Prop({ type: String, required: true }) public documentType!: string;

  protected content: string = '';

  public mounted(): void {
    const api: VenueApiService = new VenueApiService();

    const type: string = this.$route.params.type || '';

    switch (type) {
      case 'terms':
        api
          .getVenueTerms({ id: this.$route.params.id })
          .then((res: AxiosResponse<string>) => (this.content = res.data));
        break;
      case 'imprint':
        api
          .getVenueImprint({ id: this.$route.params.id })
          .then((res: AxiosResponse<string>) => (this.content = res.data));
        break;
      case 'privacy':
        api
          .getVenuePrivacy({ id: this.$route.params.id })
          .then((res: AxiosResponse<string>) => (this.content = res.data));
        break;
    }
  }
}
